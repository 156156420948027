import { useEffect, useState } from 'preact/hooks';
import styled from '@emotion/styled/macro';
// import "../../assets/scss/stripe.scss";

import {
  initialPaymentIntent,
  paymentIntent,
  updateIntent

} from '../../utils/paymentApi';

import ReactGA from "react-ga";

import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import Button from '../Button';
import { PRODUCTS } from './constants';
import Products from './Products';
import CheckoutForm from "./CheckoutForm";
import Processing from './Processing';
import Success from './Success';

const initProduct = PRODUCTS[1];

const PaymentForm = ({ close, status, setStatus, user, updateUser, promise }) => {
  const [clientSecret, setClientSecret] = useState("");
  const [currentPaymentIntent, setCurrentPaymentIntent] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [selectedProduct, setSelectedProduct] = useState(initProduct);
  const [email, setEmail] = (user&&(useState(user.mail))||"anon@cartocuts.com");

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [step1, setStep1] = useState(false);

  /**
   * On modal open, show error if no user logged in.
   * Otherwise, clear any previous status
   */


  useEffect(async() => {

    console.log('[useEffect] in payment');
    //console.log(selectedProduct)

    //const payload = await paymentIntent(user.stripe_customer_id, selectedProduct.code, setClientSecret, setCurrentPaymentIntent)

      if (user) {
      setStatus(null);
      ReactGA.event({
          category: 'User',
          action: 'Authenticated View Checkout'
      });
    }

  }, []);


  const appearance = {
    theme: 'stripe',
  };
  const options = {
    clientSecret,
    appearance,
  };


  /** update selected product */
  const radioChange = event => {
    const product = PRODUCTS.find(product => product.id === event.target.id);
    //const updatePaymentIntent = updateIntent(currentPaymentIntent, product.code);
    setSelectedProduct(product);

  };

  function payNow() {
    const payload = paymentIntent(user.stripe_customer_id, selectedProduct.code, setClientSecret, setCurrentPaymentIntent)
    setStep1(true);
  };


  return (
    <>
      <CenteredFormGroup>
        {status === 'processing' ? (
        <Processing />
        ) : status === 'success' && (
          <Success close={close} selectedProduct={selectedProduct} />
        )}
      </CenteredFormGroup>

      {!step1 &&(
      <CenteredFormGroup>
        {status === 'processing' ? (
          <Processing />
        ) : status === 'success' ? (
          <Success close={close} selectedProduct={selectedProduct} />
        ) : (
          <Products
            radioChange={radioChange}
            selectedProduct={selectedProduct}
          />
        )}
        <Button variant="secondary" className="pay-now-button" onClick={payNow}>Pay Now</Button>
      </CenteredFormGroup>
      )}

        {clientSecret && !status && step1 &&(
          <div className="payment-wrap">
          <h3>You have selected the {selectedProduct.title} plan.</h3>
          <h4>Please proceed to pay ${selectedProduct.price}</h4>
          <Elements options={options} stripe={promise}>
            <CheckoutForm
              user={user}
              status={setStatus}
              setStatus={setStatus}
              selectedProduct={selectedProduct}
              updateUser={updateUser}
            />
          </Elements>
          </div>
        )}

    </>
  );
};

const CenteredFormGroup = styled.div`
  align-items: center;
`;

export default PaymentForm;
