import React, { useEffect, useState } from "react";
import {
  PaymentElement,
  LinkAuthenticationElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";
import {
  grantCredit,
  updateCredit

} from '../../utils/paymentApi';

import "../../assets/scss/stripe.scss";

import ReactGA from "react-ga";

export default function CheckoutForm({user, status, setStatus, selectedProduct, updateUser}) {
  const stripe = useStripe();
  const elements = useElements();

  const [email, setEmail] = (user&&(useState(user.mail))||"anon@cartocuts.com")
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);


  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage("Payment succeeded!");
          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.");
          break;
        default:
          setMessage("Something went wrong.");
          break;
      }
    });
  }, [stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const response = await stripe.confirmPayment({
     elements,
     confirmParams: {
      },
     redirect: 'if_required'
    });

    if (response.error) {
      //something went wrong
     setMessage(response.error.message);
    } else {
      //payment successful
     setMessage(`Payment Successful`);


   // grant credits
      const grantCreditResponse = await grantCredit(
          selectedProduct.price,
          selectedProduct.credit,
          response.paymentIntent.id,
          user.uid
        );

        const updateCreditResponse = await updateCredit(
          selectedProduct.credit,
          user
        );

        await updateUser();
       // console.log(grantCreditResponse, updateCreditResponse);
        setStatus('success');


        ReactGA.event({
          category: 'Payment',
          action: 'Successful Payment'
        });
    }


    // const { error } = await stripe.confirmPayment({
    //   elements,
    //   confirmParams: {
    //     // Make sure to change this to your payment completion page
    //     return_url: "https://v3.cartocuts.com/preview",
    //   },
    //     redirect: 'if_required',
    // });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.

    // if (error.type === "card_error" || error.type === "validation_error") {
    //   setMessage(error.message);
    // } else {
    //   setMessage("An unexpected error occurred.");
    // }

    setIsLoading(false);
  };

  const paymentElementOptions = {
    layout: "tabs"
  }

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
    {user.mail &&(
      <LinkAuthenticationElement
        id="link-authentication-element"
          options={{
                  defaultValues: {
                    email: email,
                  },
                }}
      />
      )}
      <PaymentElement id="payment-element" options={paymentElementOptions} />
      <button disabled={isLoading || !stripe || !elements} id="submit">
        <span id="button-text">
          {isLoading ? <div className="spinner" id="spinner"></div> : "Pay now"}
        </span>
      </button>
      {/* Show any error or success messages */}
      {message && <div id="payment-message">{message}</div>}
    </form>
  );
}