import { BASE_URL } from './constants';


//API Get Node info
export async function getNode(id) {

  // Get Node Info
  var GetNodeHeaders = new Headers();
  GetNodeHeaders.append("Content-Type", "application/vnd.api+json");
  GetNodeHeaders.append("Accept", "application/vnd.api+json");


  var requestOptions = {
  method: 'GET',
  headers: GetNodeHeaders,
  redirect: 'follow'
};

    const content = fetch(`${BASE_URL}/api/page/${id}`, requestOptions)
      .then(response => response.json())
      .then(result => result)
      return content
}