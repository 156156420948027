import { useState, useEffect } from 'preact/hooks';
/**
 * Custom hook to keep state in localStorage
 * modified from Kent C. Dodds, EpicReact.dev
 * @param {String} key The key to set in localStorage for this value
 * @param {Object} defaultValue The value to use if it is not already in localStorage
 * @param {{serialize: Function, deserialize: Function}} options The serialize and deserialize functions to use (defaults to JSON.stringify and JSON.parse respectively)
 */

function useLocalStorageState(
  key,
  defaultValue = '',
  { serialize = JSON.stringify, deserialize = JSON.parse } = {}
) {
  const [state, setState] = useState(() => {
    let valueInLocalStorage;

    // required check so app build doesn't fail
    if (typeof window !== 'undefined') {
      valueInLocalStorage = window.localStorage.getItem(key);
    }

    if (valueInLocalStorage) {
      return deserialize(valueInLocalStorage);
    }

    return defaultValue;
  });

  useEffect(() => {
    // required check so app build doesn't fail
    if (typeof window !== 'undefined') {
      window.localStorage.setItem(key, serialize(state));
    }
  }, [key, state, serialize]);

  return [state, setState];
}

export { useLocalStorageState };
