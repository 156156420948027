import { css } from '@emotion/react';

const GlobalStyles = css`
  :root {
    /* colours */
    --primary-hsl: 37, 100%, 59%;
    --secondary-hsl: 15, 100%, 59%;
    --tertiary-hsl: 205, 100%, 59%;

    --primary-color: hsla(var(--primary-hsl), 1);
    --primary-color-light: hsla(37, 100%, 67%, 1);
    --secondary-color: hsla(var(--secondary-hsl), 1);
    --tertiary-color: hsla(var(--tertiary-hsl), 1);

    /* grays */
    --gray-100: hsla(0, 0%, 95%, 1);
    --gray-200: hsla(0, 0%, 85%, 1);
    --gray-300: hsla(0, 0%, 65%, 1);
    --gray-500: hsla(0, 0%, 35%, 1);
    --gray-900: hsla(0, 0%, 17%, 1);

    --light-transparent: hsla(0, 0%, 100%, 0.8);
    --dark-transparent: hsla(0, 0%, 35%, 0.8);

    --white: white;
    --black: black;

    /** spacing */
    --spacing-tiny: calc(5 / 16 * 1rem);
    --spacing-small: calc(10 / 16 * 1rem);
    --spacing-medium: calc(24 / 16 * 1rem);
    --spacing-large: calc(48 / 16 * 1rem);

    /** typography */
    --color: var(--black);
    
    /** Change form input colour where supported */
    accent-color: var(--primary-color);

  }

  html,
  body,
  #app {
    height: 100%;
  }
  body {
   background-color: var(--white);
   font-family: 'Roboto Slab', serif;
   
  }

  a {
    color: var(--color);
    font-weight: 700;
  }

  /** A modern css reset by Andy Bell https://piccalil.li/blog/a-modern-css-reset/ */
  /* Box sizing rules */
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  /* Remove default margin and padding */
  body,
  h1,
  h2,
  h3,
  h4,
  p,
  fieldset,
  figure,
  legend,
  blockquote,
  dl,
  dd,
  ul,
  ol {
    margin: 0;
    padding: 0;
  }

  /* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
  ul,
  ol {
    list-style: none;
  }

  /* Set core root defaults */
  html:focus-within {
    scroll-behavior: smooth;
  }

  /* Set core body defaults */
  body {
    min-height: 100vh;
    text-rendering: optimizeSpeed;
    line-height: 1.5;
  }

  /* A elements that don't have a class get default styles */
  a:not([class]) {
    text-decoration-skip-ink: auto;
  }

  /* Make images easier to work with */
  img,
  picture {
    max-width: 100%;
    display: block;
  }

  /* Inherit fonts for inputs and buttons */
  input,
  button,
  textarea,
  select {
    font: inherit;
  }

  /* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
  @media (prefers-reduced-motion: reduce) {
    html:focus-within {
      scroll-behavior: auto;
    }

    *,
    *::before,
    *::after {
      animation-duration: 0.01ms !important;
      animation-iteration-count: 1 !important;
      transition-duration: 0.01ms !important;
      scroll-behavior: auto !important;
    }
  }

  /** Additional Cartocuts added reset */
  fieldset {
    border: none;
  }
`;

export const VARIANTS = {
  default: 'transparent',
  primary: 'var(--primary-color)',
  secondary: 'var(--secondary-color)',
  tertiary: 'var(--tertiary-color)',
  gray: 'var(--gray-300)',
};

export default GlobalStyles;
