const Logo = ({ width, height }) => {
  const aspectRatio = 522 / 97;
  if (width) {
    height = width / aspectRatio;
  } else if (height) {
    width = height * aspectRatio;
  } else {
    width = 522;
    height = 97;
  }

  return (
    <img src={process.env.PREACT_APP_SHOP_LOGO} width="350" alt="company logo" />
  );
};

export default Logo;
