import { useState } from 'preact/hooks';
import Button from '../Button';
import Dialog from '../Dialog';
import PaymentForm from './PaymentForm';
import { Elements } from '@stripe/react-stripe-js';

/**
 * Add this Button wherever you need to summon the payment form.
 */
const Checkout = ({ promise, showCheckout, setShowCheckout, ...delegated }) => {
  const [status, setStatus] = useState('');
  const title = status === 'success' ? 'Thank you' : 'Buy Credits';
  const close = () => {
    setShowCheckout(false);
  };

  return (
    <>
      <Button variant='secondary' onClick={() => setShowCheckout(true)}>
        Buy Credits
      </Button>
      <Dialog show={showCheckout} close={close} title={title}>
          <PaymentForm
            close={close}
            status={status}
            setStatus={setStatus}
            promise={promise}
            {...delegated}
          />

      </Dialog>
    </>
  );
};

export default Checkout;
