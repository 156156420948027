import styled from '@emotion/styled/macro';
import Button from '../Button';
import Checkout from '../Checkout';
import { InputGroup } from '../Form';
import MainNavbar from './MainNavbar'
import SignInModal from '../SignInModal';
import SignUpModal from '../SignUpModal';
import Modal from '../Modal';
import VisuallyHidden from '../VisuallyHidden';

import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from 'reactstrap';

const Header = ({
    user,
    updateUser,
    login,
    logout,
    register,
    reset,
    showSignInModal,
    setShowSignInModal,
    showSignUpModal,
    setShowSignUpModal,
    showModal,
    setShowModal,
    showCheckout,
    setShowCheckout,
    stripePromise,
    content,
    setContent,
    getCurrentUrl
}) => {
    return (
      <>

        <Wrapper>
        {process.env.PREACT_APP_USER_BAR == "true" &&( 
        <nav class="navbar navbar-expand-sm bg-dark user-bar">
          {user =="" ||user == null ? (
          <ul>
          <NavItem>
            <Button
              className="nav-link"
              color="secondary"
              id="login"
              onClick={() => setShowSignInModal(true)}
            >
              <p>Login</p>
            </Button>
          </NavItem>
              <NavItem>
                <Button
                  className="nav-link"
                  color="secondary"
                  id="register"
                  onClick={() => setShowSignUpModal(true)}
                  >
                  <p>Sign Up</p>
                </Button>
              </NavItem>
              </ul>
              ):(
              <>
              <Nav className="mr-auto" navbar>
                <NavItem>
                 <span className="buy-now-button">
                   <Checkout
                    promise={stripePromise}
                    showCheckout={showCheckout}
                    setShowCheckout={setShowCheckout}
                    user={user}
                    updateUser={updateUser}
                    />
                    </span>
                </NavItem>
                  <NavItem>
                 <a href="/" className="back-button">Back to Designer</a>
                </NavItem>
                 <NavItem>
                 <a href="/maps" className="my-maps">My Maps</a>
                </NavItem>

              </Nav>
              <Nav className="ml-auto" navbar>
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret>
                    Hi {user.name || 'friend'}
                        </DropdownToggle>
                        <DropdownMenu right>
                        {/* <DropdownItem> */}
                        {/*     <Button><a href="/user/edit">Edit Profile</a></Button> */}
                        {/* </DropdownItem> */}
                          {/* <DropdownItem> */}
                          {/*   <Button><a href="https://billing.stripe.com/p/login/test_dR604513d1oFbiUdQQ" target="_blank">Manage Subscription</a></Button> */}
                          {/* </DropdownItem> */}
                          <DropdownItem>
                            <Button onClick={logout}>Logout</Button>
                          </DropdownItem>
                          <DropdownItem divider />
                          <DropdownItem>
                           {user.field_credits || 0} Downloads Remaining
                        </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                      </Nav>
                      </>
                  )}
        </nav>
        )}
        {process.env.PREACT_APP_HEADER == "true" &&(
            <div class="main-nav">
            <MainNavbar className="nav" getCurrentUrl={getCurrentUrl} />
            </div>
        )}
   
      </Wrapper>
       
      <Modal showModal = {showModal} setShowModal = {setShowModal} content={content} setContent={setContent} />
      <SignInModal
        login={login}
        reset={reset}
        showSignInModal={showSignInModal}
        setShowSignInModal={setShowSignInModal}
      />
       <SignUpModal register = { register } showSignUpModal = { showSignUpModal } setShowSignUpModal = { setShowSignUpModal }  setShowModal={setShowModal} setContent={setContent} />
       </>
  );
};



const Wrapper = styled.header `
  background-color: var(--primary-color);
`;
const TitleHeader = styled.div `
  align-items: center;
  display: flex;
  flex-grow: 0;
  font-weight: 700;
  justify-content: space-between;
  padding: 1.5rem 2rem;
`;

const SiteName = styled.h1 `
  line-height: 0;
  margin-bottom:0 !important;
`;

const Spacer = styled.span `
  flex-grow: 1;
`;

const Greeting = styled.div `
  flex-grow: 0;
 /* text-transform: capitalize;*/
`;


export default Header;