import { useState } from 'preact/hooks';
import styled from '@emotion/styled/macro';
import Dialog from '../Dialog';
import Button from '../Button';
import Markdown from 'preact-markdown';


const Modal = ({showModal, setShowModal, content, setContent}) => {
  const [error, setError] = useState(null);


  const close = () => {
    setShowModal(false);
    setError(null);
  };

  const handleSubmit = async (id, event, callback) => {
    setError(null);
    const error = await callback(event);
    if (error) {
      console.error(error);
      setError({ id, message: error.message });
    } else close();
  };

  return (
    <Dialog
      show={showModal}
      close={close}
      title= {content && (content.attributes.title)}>
      <Wrapper>
      <Markdown markdown={content && (content.attributes.body.value)} />
        <Divider />

      </Wrapper>
    </Dialog>
  );
};


const Divider = styled.span`
  border-right: 1px solid var(--gray-300);
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 2rem;
`;

export default Modal;
