import styled from '@emotion/styled/macro';
import Button from '../Button';
import { SmallText, MediumText } from '../Text';

const Success = ({ close, selectedProduct }) => {
  return (
    <>
      <SuccessText>Your transaction was successful</SuccessText>
      <Wrapper>
        <SmallText>Your account has been credited</SmallText>
        <MediumText>
          {selectedProduct?.credit} Download Credit
          {selectedProduct.credit > 1 ? 's' : ''}
        </MediumText>
        <SmallText>An invoice has been sent to your email</SmallText>
      </Wrapper>
      <Button variant='secondary' onClick={close}>
        Back to my Map
      </Button>
    </>
  );
};

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 3rem 0;
`;

const SuccessText = styled(MediumText)`
  font-weight: 300;
`;

export default Success;
