import { getMapStyle } from '../components/mapComponents/utils/constants';

export const BASE_URL = process.env.PREACT_APP_SHOP_API;
export const STRIPE_API = process.env.PREACT_APP_STRIPE_API;

export const roadStyle = 'mapbox://styles/thecutandetch/cl6dwy2ss000515pcjwdbcvjd';
export const waterStyle = 'mapbox://styles/thecutandetch/cl6dv5ta7000015muvnyyhbub';

export const INIT_MAP = {
  customMarker: [],
  labels: [],
  layers: [],
  mapSize: {
    width: 12,
    height: 8,
    units: 'inch',
  },
  mapStyle: getMapStyle('Engrave').style,
  mapStyleName: '',
  mapTitle: '',
  viewport: {
    longitude: -117.2839,
    latitude: 49.4912,
    zoom: 12.3,
    bearing: 0,
    pitch: 0,
  },
};
