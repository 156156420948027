import styled from '@emotion/styled/macro';
import { DialogOverlay, DialogContent } from '@reach/dialog';
import Button from '../Button';
import VisuallyHidden from '../VisuallyHidden';

const Dialog = ({ show, close, title, children, width, height }) => {
  return (
    <Overlay isOpen={show} onDismiss={close}>
      <Content aria-labelledby='dialog-title'>
        <CloseButton icon='x' variant='tertiary' onClick={close}>
          <VisuallyHidden>Close</VisuallyHidden>
        </CloseButton>
        <Title id='dialog-title'>{title}</Title>
        {children}
      </Content>
    </Overlay>
  );
};

const Overlay = styled(DialogOverlay)`
  background: hsla(var(--primary-hsl), 0.7);
  z-index:4;
`;

const Content = styled(DialogContent)`
  box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.2);
  padding: 2rem 45px 45px; /* 45px is the height of the close button */
  width: min(80ch, 90%);
  border-radius: 15px;

`;

const CloseButton = styled(Button)`
  border-radius: 0;
  margin-left: auto;
  margin-top: -2rem;
  background-color: var(--secondary-color) !important;
  width:50px;
  &:focus{
    opacity:1;
    color:white;
  }
`;

const Title = styled.h2`
  font-weight: 300;
  margin-bottom: 2rem;
  text-align: center;
  text-transform: uppercase;
`;

export default Dialog;
