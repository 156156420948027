import { h } from 'preact';
import styled from '@emotion/styled/macro';
import { VARIANTS } from '../GlobalStyles';

import Icon from '../Icon';

const Button = ({
  children,
  icon,
  size = 24,
  strokeWidth = 2,
  variant = 'default',
  ...delegated
}) => {
  return (
    <Wrapper variant={variant} {...delegated}>
      {icon && <StyledIcon id={icon} size={size} strokeWidth={strokeWidth} />}
      {children}
    </Wrapper>
  );
};

const Wrapper = styled.button`
  --variant-color: ${props => VARIANTS[props.variant]};

  align-items: center;
  background: var(--variant-color);
  border: 1px solid var(--variant-color);
  border-radius: 50px;
  color: ${props =>
  props.variant === 'secondary' ? 'var(--white)' : 'inherit'};
  cursor: pointer;
  display: flex;
  font: inherit;
  font-weight: ${props => (props.variant === 'secondary' ? 700 : 'normal')};
  gap: var(--spacing-small);
  justify-content: center;
  margin: 0;
  outline-offset: 4px;
  padding: ${props =>
    props.variant === 'default'
      ? '10px var(--spacing-small) 9px'
      : 'var(--spacing-small) var(--spacing-medium)'};
  text-align: left;
  text-decoration: none;
  text-transform: uppercase;

  &:focus:not(:focus-visible) {
    outline: none;
  }
  &:hover{
    opacity: 0.8;
    color:black;
    text-decoration:none;
  }
  &:focus {
    opacity: 0.5;
    color:black;
    text-decoration:none;

  }
`;

const StyledIcon = styled(Icon)`
  color: inherit;
  pointer-events: none;
`;

export default Button;
