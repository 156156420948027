import styled from '@emotion/styled/macro';
import Alert from '@reach/alert';

export const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-tiny);
`;

export const FormGroupTitle = styled.h3`
  color: var(--black);
  font-size: calc(16 / 16 * 1rem);
  font-weight: 700;
  text-transform: uppercase;
  margin-top:10px;
`;

export const InputGroup = styled.div`
  align-items: center;
  display: flex;
  gap: var(--spacing-small);
  justify-content: space-between;
`;

export const InputGroupTitle = styled.h4`
  color: var(--gray-500);
  font-size: calc(14 / 16 * 1rem);
  margin-bottom:0.5rem;
  font-weight: 700;
`;

export const ValidationMessage = styled(Alert)`
  background-color: hsla(var(--secondary-hsl), 0.2);
  border-color: hsla(var(--secondary-hsl), 0.8);
  border-radius: 2px;
  border-style: solid;
  color: hsl(15, 100%, 10%);
  margin-top: -1rem;
  padding: 0.25rem 1rem;
`;
