import { h } from 'preact';
import styled from '@emotion/styled/macro';

import Icon from '../Icon';
import VisuallyHidden from '../VisuallyHidden';

const TextInput = ({ label, icon, width, className, ...delegated }) => {
  return (
    <InputWrapper className={className} width={width}>
      <VisuallyHidden>{label}</VisuallyHidden>
      {icon && <InputIcon id={icon} size={24} strokeWidth={2} />}
      <Input hasIcon={Boolean(icon)} {...delegated} />
    </InputWrapper>
  );
};
export default TextInput;

export const Input = styled.input`
  border: 1px solid var(--gray-200);
  border-radius: 50px;
  color: inherit;
  font-size: 14px;
  font-weight: 700;
  height: 100%;
  outline-offset: 4px;
  padding: var(--spacing-small);
  padding-left: ${props => (props.hasIcon ? '54px' : 'var(--spacing-medium)')};
  width: 100%;
  &::placeholder {
    color: var(--gray-500);
    font-weight: 400;
    text-transform: uppercase;
  }
`;

export const InputIcon = styled(Icon)`
  bottom: 0;
  color: inherit;
  margin: auto 22px;
  /* margin-left: 22px; */
  pointer-events: none;
  position: absolute;
  top: 0;
`;

export const InputWrapper = styled.label`
  color: var(--gray-900);
  position: relative;
  min-width:80px;
  width: ${props => props?.width + 'px' || 'initial'};
  &:hover {
    color: var(--black);
  }
`;
