import { useState, useEffect } from 'preact/hooks';
import styled from '@emotion/styled/macro';
import Dialog from '../Dialog';
import { FormGroup, FormGroupTitle, ValidationMessage } from '../Form';
import TextInput from '../TextInput';
import Checkbox from '../Checkbox';
import Button from '../Button';
import { getNode } from '../../utils/pageApi';

// reactstrap components
import {
    Input,
    Container,
    Row,
    Col,
} from "reactstrap";


const SignUpModal = ({register, showSignUpModal, setShowSignUpModal, setShowModal, showModal, content, setContent }) => {
  const [error, setError] = useState(null);
  const [checked, setChecked] = useState(1);
  const [termsChecked, setTermsChecked] = useState();

  const close = () => {
    setShowSignUpModal(false);
    setError(null);
  };

  const handleSubmit = async (id, event, callback) => {
    setError(null);
    const error = await callback(event);
    if (error) {
      console.error(error);
      setError({ id, message: error.message });
    } else close();
  };

  const handleRegister = event => {
    handleSubmit('sign-up', event, register);
  };

  /**
   *Load in terms and conditions text
   */
    useEffect(() => {
        getNode("bcef1f59-c961-4746-a043-b715e917d97d").then(response => setContent(response.data));
    }, []);

  return (
    <Dialog
      show={showSignUpModal}
      close={close}
      title='Sign Up'>
      <Wrapper>
        <Form id='sign-up' as='form' onSubmit={handleRegister}>
          {error?.id === 'sign-up' ? (
            <ValidationMessage>{error.message}</ValidationMessage>
          ) : null}
            <Row>
          <p>Sign up to generate high resolution topographic and city maps and choose and pay for a license to suit your usage.</p>
          <br/> <br/>
          </Row>
          <Row>
          <Col>
             <TextInput
              icon='user'
              label='username'
              name='username'
              placeholder='username *'
              required='required'
            />
          </Col>
          <Col>
            <TextInput
            icon='star'
            label='company'
            name='company'
            placeholder='company'
          />
          </Col>

          </Row>
          <Row>
          <Col>
          <TextInput
            icon='user'
            label='first name'
            name='firstname'
            placeholder='first name *'
            required='required'
          />
          </Col>
          <Col>
          <TextInput
           icon='user'
            label='last name'
            name='lastname'
            placeholder='last name *'
            required='required'
          />
          </Col>

          </Row>
          <Row>
          <Col>
         <TextInput
            icon='mail'
            label='email'
            name='email'
            placeholder='email *'
            required='required'
            type='email'
            pattern='\A[A-Z0-9+_.-]+@[A-Z0-9.-]+\Z'
          />
          </Col>
          <Col>
          <TextInput
            icon='key'
            label='password'
            name='password'
            placeholder='password *'
            required='required'
            type='password'
          />
          </Col>
          </Row>
          <Row>
          <Col>
              <div className="terms-conditions">
            <Checkbox
              label='Receive product announcements and new feature updates'
               value = {checked?1:0}
               name = 'newsletter'
               checked={checked}
               onChange={e => setChecked(e.target.checked)}

            />
              <Checkbox
                 label={`I agree to the `}
                 linkname="Terms & Conditions"
                 setShowModal={setShowModal}
                 value = {termsChecked?1:0}
                 name = 'terms'
                 checked={termsChecked}
                 onChange={e => setTermsChecked(e.target.checked)}
                 required='required'
              />
            </div>

          <Button variant='secondary' type='submit'>
            Sign Up
          </Button>
          </Col>
          </Row>
        </Form>

      </Wrapper>
    </Dialog>
  );
};

const Form = styled(FormGroup)`
  flex: auto;
  gap: var(--spacing-medium);
  width: 0;
  button {
    color: var(--white);
    font-weight: 700;
  }
`;

const Divider = styled.span`
  border-right: 1px solid var(--gray-300);
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 2rem;
`;

export default SignUpModal;
