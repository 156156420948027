import styled from '@emotion/styled/macro';
import { PRODUCTS } from './constants';
import { InputGroup, InputGroupTitle } from '../Form';
import Radio from '../Radio';
import { SmallText } from '../Text';

const Products = ({ radioChange, selectedProduct }) => {
  return (
    <>
      <h4 className="text-center">Buy in bulk and save up to 50% on licenses</h4>

      {/* <SmallText> */}
      {/*   {selectedProduct.credit} Download */}
      {/*   {selectedProduct.credit > 1 ? 's' : ''} for ${selectedProduct.price} */}
      {/* </SmallText> */}
    <div className="product-selector">
      <VerticalGroup as='fieldset'>
        {PRODUCTS.map(product => (
          <>

          <span className={product.id === selectedProduct.id ?("credit-plan active"):("credit-plan")}>
          <Radio
            checked={product.id === selectedProduct.id}
            key={product.id}
            id={product.id}
            name='product-options'
            className="radio-select"
            onClick={radioChange}
            value={product.price}>
            <h3 className="title">{product.title}</h3>
            <span className="price">${product.price}</span>
            <div class="ribbon ribbon-top-right"><span>{product.save}</span></div>
            {product.credit} Credit{product.credit > 1 ? 's' : ''}
            <p className="each">{product.each}</p>
            <p className="description">{product.description}</p>
          
          </Radio>
          </span>

          </>
        ))}
      </VerticalGroup>
      </div>
    </>
  );
};

const VerticalGroup = styled(InputGroup)`
  align-items: flex-start;
  flex-direction: row;
  gap: var(--spacing-small);
  margin-top:20px;

  label{
    font-size:20px;
    background:#ececec;
    border-radius:15px;
    width:100%;
    padding: 20px 30px;
    box-shadow: 0 0 15px rgba(0,0,0,.1);
    position:relative;
    width:200px;
    height:350px;
    display:inline-block;
    padding-top:130px !important;
    //color:white;
  }
  p{
    font-style:italic;
    font-size:14px;
    clear:both;
  }
`;

export default Products;
