import { h } from 'preact';
import styled from '@emotion/styled/macro';

const Footer = () => (
  <Wrapper>
    Copyright © {new Date().getFullYear()} Cartocuts All Rights Reserved.
  </Wrapper>
);

const Wrapper = styled.footer`
  background-color: var(--gray-900);
  color: var(--white);
  font-size: calc(12 / 16 * 1rem);
  padding: 1rem 2rem;
  text-align: center;
`;

export default Footer;
