import { h } from 'preact';
import styled from '@emotion/styled/macro';

const Radio = ({ children, ...delegated }) => {
  return (
    <Label>
      <Input type='radio' {...delegated} />
      {children}
    </Label>
  );
};

const Label = styled.label`
  display: flex;
  gap: 4px;
  line-height: 1.4;
`;

const Input = styled.input``;

export default Radio;
