export const PRODUCTS = [
  // {
  //   id: 'single',
  //   credit: 1,
  //   code: 'price_0JMI1VERgOuD5W8CxVQABC6p',
  //   price: 10,
  // },
  {
    id: 'five',
    credit: 5,
    code: 'price_0HRt06ERgOuD5W8CDH4H8uUR',
    title:'Starter',
    save:'Save 10%',
    description:'A starter pack for occasional map designers.',
    each:'$9 per credit',
    price: 45,

  },
  {
    id: 'ten',
    credit: 10,
    code: 'price_0HRsvMERgOuD5W8CdmsyWKAt',
    title:'Hobby',
    save:'Save 20%',
    each:'$8 per credit',
    description:'Good value for more demanding map makers.',
    price: 80,
  },
  {
    id: 'twenty',
    credit: 20,
    code: 'price_0HRszMERgOuD5W8CgErDHOLK',
    title:'Maker',
    save:'Save 30%',
    each:'$7 per credit',
    description:'Great value for makers looking to build map product lines.',
    price: 140,
  },
  {
    id: 'fifty',
    credit: 50,
    code: 'price_0HRsuDERgOuD5W8CvJJmtTnc',
    title:'Pro',
    save:'Save 50%',
    each:'$5 per credit',
    description:'Excellent value for cartographers who make with maps all the time.',
    price: 250,
  }
];
