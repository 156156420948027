import Loader from '../Loader';
import { InputGroupTitle } from '../Form';

const Processing = () => {
  return (
    <>
      <InputGroupTitle>Processing payment</InputGroupTitle>
      <Loader size='100px' style={{ marginTop: '2rem' }} />
    </>
  );
};

export default Processing;
