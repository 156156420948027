import styled from '@emotion/styled/macro';

const Loader = styled.div`
  /* adapted from https://codepen.io/l-e-e/pen/QWvxYrG */
  --size: ${props => props.size || '80px'};
  width: var(--size);
  height: var(--size);
  border-radius: 50%;
  border: calc(var(--size) / 10) solid transparent;
  border-top: calc(var(--size) / 10) solid var(--secondary-color);
  border-bottom: calc(var(--size) / 10) solid var(--secondary-color);
  animation: rotate 1.8s linear infinite;

  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export default Loader;
