// Helper utilities to check and parse fetch requests
// Adapted from https://github.com/react-boilerplate/react-boilerplate/blob/master/app/utils/request.js

/**
 * Parses the JSON returned by a network request
 *
 * @param  {object} response A response from a network request
 * @return {object}          The parsed JSON from the request
 */
async function parseJSON(response) {
  if (response.status === 204 || response.status === 205) {
    return null;
  }

  try {
    const contentType = response.headers.get('content-type');
    if (contentType && contentType.indexOf('application/json') !== -1) {
      const data = await response.json();
      // console.log({ data });
      return data;
    }
    const text = await response.text();
    // console.log({ text });
    return text;
  } catch (error) {
    console.log('caught error parsing response');
    // logout returns 200 and an html page we're not interested in,
    // so return nothing
    return null;
  }
}

/**
 * Checks if a network request came back fine, and throws an error if not
 *
 * @param  {object} response   A response from a network request
 * @return {object|undefined} Returns either the response, or throws an error
 */
async function checkStatus(response) {
  console.log({ response });
  if (response.status >= 200 && response.status < 300) {
    return response;
  }

  const error = new Error(response.statusText);
  const { message } = await response.json();
  error.message = message;
  throw error;
}

/**
 * Requests a URL, returning a promise
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 * @return {object}           The response data
 */
export default function request(url, options) {
  return fetch(url, options).then(checkStatus).then(parseJSON);
}
