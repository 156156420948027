import React from "react";
import Logo from '../Logo';
// reactstrap components
import {
    Button,
    Collapse,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    NavbarBrand,
    Navbar,
    NavItem,
    NavLink,
    Nav,
    Container,
    UncontrolledTooltip,
} from "reactstrap";
import { getCurrentUrl } from "preact-router";

function MainNavbar() {
    const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");
    const [collapseOpen, setCollapseOpen] = React.useState(false);
    const currentUrl = getCurrentUrl();
    React.useEffect(() => {
        console.log(getCurrentUrl())
        
        const updateNavbarColor = () => {
            if (
                document.documentElement.scrollTop > 399 ||
                document.body.scrollTop > 399
            ) {
                setNavbarColor("");
            } else if (
                document.documentElement.scrollTop < 400 ||
                document.body.scrollTop < 400
            ) {
                setNavbarColor("navbar-transparent");
            }
        };
        window.addEventListener("scroll", updateNavbarColor);
        return function cleanup() {
            window.removeEventListener("scroll", updateNavbarColor);
        };
    });
    return ( <
        > {
            collapseOpen ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setCollapseOpen(false);
          }}
        />
            ) : null
        }
        <Navbar className={navbarColor} expand="lg">
        <Container>
          <div className="navbar-translate">
            <NavbarBrand
              href="/"
              id="navbar-brand"
            >
              <Logo
              width="280px"/>
            </NavbarBrand>
            <UncontrolledTooltip target="#navbar-brand">
              Maps for Makers
            </UncontrolledTooltip>
            <button
              className="navbar-toggler"
              onClick={() => {
                document.documentElement.classList.toggle("nav-open");
                setCollapseOpen(!collapseOpen);
              }}
              aria-expanded={collapseOpen}
              type="button"
            >
              <span className="navbar-toggler-bar top-bar"></span>
              <span className="navbar-toggler-bar middle-bar"></span>
              <span className="navbar-toggler-bar bottom-bar"></span>
            </button>
          </div>
          <Collapse
            className="justify-content-end"
            isOpen={collapseOpen}
            navbar
          >
            <Nav navbar>
            <NavItem
               className={currentUrl== "/products" &&("active")}>
                <NavLink
                  href="/products"
                >
                  <p><span>1.</span> Choose Product</p>
                </NavLink>
                </NavItem>
              <NavItem
                 className={currentUrl == "/make-map" &&("active")}>
                <NavLink
                  href="/make-map"
                >
                  <p><span>2.</span>Choose Map</p>
                </NavLink>
              </NavItem>
                <NavItem
                   className={currentUrl == "/preview" &&("active")}>
                <NavLink
                  href="/preview"
                >
                  <p><span>3.</span> Checkout</p>
                </NavLink>
              </NavItem>
              
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
      </>
    );
}

export default MainNavbar;