export const MAPBOX_TOKEN =
  'pk.eyJ1IjoidGhlY3V0YW5kZXRjaCIsImEiOiJja2VyaGE1amwxNWhuMnFuMTI5NW4xNGtsIn0.iewMUA5hOiI3Sfh0EZP2zQ';


// export const USERNAME = process.env.PREACT_APP_USERNAME;
// export const PASSWORD = process.env.PREACT_APP_PASSWORD;

export const licenseDefault = {
  "type": "taxonomy_term--licensing",
  "id": "44a3647c-10b5-46de-8ec8-515209157021",
  "attributes": {
    "name": "Personal License",
    "description": {
      "value": "**Your map can be used for a personal non-commercial project**\r\n- End products not for sale\r\n- One personal non-commercial social media account\r\n- No physical or digital paid advertisements\r\n- No native apps, web apps, or games",
      "format": "markdown",
      "processed": "<p><strong>Your map can be used for a personal non-commercial project</strong></p>\n<ul><li>End products not for sale</li>\n<li>One personal non-commercial social media account</li>\n<li>No physical or digital paid advertisements</li>\n<li>No native apps, web apps, or games</li>\n</ul>"
    },
    "weight": 0,
    "changed": "2022-08-24T19:48:17+00:00",
    "default_langcode": true,
    "revision_translation_affected": true,
    "metatag": null,
    "path": {
      "alias": null,
      "pid": null,
      "langcode": "en"
    },
    "field_license_price": "10.00",
    "field_short_description": "The licensed asset can appear in a non-commercial project",
    // this is the test
    //"field_stripe_id": "price_0LSQ60ERgOuD5W8CILALc3Xo",
    "field_stripe_id": "price_0L7OBKERgOuD5W8CjZjCynFV",
    "field_woocommerce_product_id": "6646"
  }
}

// export const LICENSES= [
//   {
//     id: 'personal',
//     tid: '44a3647c-10b5-46de-8ec8-515209157021',
//     name: 'Personal License',
//     description: 'You may use this image for a single non-commercial use for yourself or someone else.',
//     // live code: 'price_0L7OBKERgOuD5W8CjZjCynFV',
//     code: 'price_0LSQ60ERgOuD5W8CILALc3Xo',
//     credit: 1,
//     price: 10,
//   },
//   {
//     id: 'commercial_single',
//     tid: '96ad29ef-d85c-4dcd-8d51-06f67a101215',
//     name: 'Commercial Single',
//     description: 'You may use this map for a single instance of a commercial product for a customer',
//     credit: 2,
//     code: 'price_0LAo7kERgOuD5W8CpbAW1cgK',
//     price: 20,
//   },
//   {
//     id: 'commercial_multiple',
//     name: 'Commercial Multiple',
//     tid: '6ebe4f5e-1e03-4195-ae7b-f61b22cba3dc',
//     description: 'You may use this map on any product that you produce and sell up to 1000 units.',
//     credit: 10,
//     code: 'price_0LAoASERgOuD5W8CyxjT0dVy',
//     price: 100,
//   },
//   {
//     id: 'commercial_extended',
//     tid: '61399acf-cc47-4d3f-b64a-652284d86166',
//     name: 'Commercial Extended',
//     description: 'You may use this map on a range of products with no limitations on quantity',
//     credit: 100,
//     code: 'price_0LAoCkERgOuD5W8CK9DApQsv',
//     price: 1000,
//   }
// ];



export const MAP_STYLES = [{
    name: 'Engrave',
    style: 'mapbox://styles/thecutandetch/ckf1azt8v2qg219pnp3vpsner',
    // style: require('../../map_styles/cartocuts-studio.json'),
  },
  {
    name: 'Water',
    style: 'mapbox://styles/thecutandetch/cl6dv5ta7000015muvnyyhbub',
  },
  {
    name: 'Roads',
    style: 'mapbox://styles/thecutandetch/cl6dwy2ss000515pcjwdbcvjd',
  }
];

export const MAP_LAYERS = {
  LAYERS: [{
      name: 'contours',
      layers: ['contour_lines', 'contour_index_lines'],
    },
    {
      name: 'hillshade',
      layers: ['shadow1', 'shadow2'],
    },
    {
      name: 'waterways',
      layers: ['waterway_shadow'],
    },
    {
      name: 'trails',
      layers: ['trails', 'trail_bridges'],
    },
    {
      name: 'roads',
      layers: [
        'road_number_shield',
        'road_motorway_trunk',
        'road_motorway_trunk_case',
        'road_primary',
        'road_primary_case',
        'road_intersection',
        'road_secondary_tertiary',
        'road_secondary_tertiary_case',
        'bridge_primary_secondary_tertiary',
        'road_major_link',
        'road_street',
        'road_minor',
        'road_major_link_case',
        'road_secondary_tertiary_case',
        'road_street_case',
        'road_street_low',
        'road_minor_case',
        'road_minor_low',
      ],
    },
    {
      name: 'buildings',
      layers: ['building', 'building_outline'],
    },
  ],

  LABELS: [{
      name: 'peaks',
      layers: ['summit_label'],
    },
    {
      name: 'elevations',
      layers: ['elevation_labels', 'summit_elevation_label'],
    },
    {
      name: 'cities',
      layers: ['city_label', 'city_small_label'],
    },
    {
      name: 'suburbs',
      layers: ['suburb_label'],
    },
    {
      name: 'trails',
      layers: ['trail_labels'],
    },
    {
      name: 'roads',
      layers: ['road_label'],
    },
  ],
};

export const getMapStyle = name =>
  MAP_STYLES.find(style => style.name === name);