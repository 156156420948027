import { useEffect, useState } from 'preact/hooks';
import { Router, route, getCurrentUrl } from 'preact-router';
import ReactGA from "react-ga";
import styled from '@emotion/styled/macro';
import { Global } from '@emotion/react';
import GlobalStyles from './GlobalStyles';
import Header from './Header';
import MapConfigurator from '../routes/MapConfigurator';
import MapPreview from '../routes/MapPreview';
import MyMaps from '../routes/Maps';
import UserEdit from '../routes/UserEdit';
import UserReset from '../routes/UserReset';
import SignUpModal from './SignUpModal';
import SignInModal from './SignInModal';
import UserSignUp from '../routes/UserSignUp';
import UserSignIn from '../routes/UserSignIn';

import Products from '../routes/Products';
import Footer from './Footer';
import TagManager from 'react-gtm-module'

// styles for this kit
import "../assets/css/bootstrap.min.css";
import "../assets/scss/cartocuts.scss";
import 'mapbox-gl/dist/mapbox-gl.css';
import '@reach/dialog/styles.css';
import "@fontsource/roboto-slab"; // Defaults to weight 400.
import "@fontsource/roboto-slab/300.css"; // 300
import "@fontsource/roboto-slab/700.css"; // 700

import { useLocalStorageState } from '../hooks/useLocalStorage';
import {
    getLoginStatus,
    getUserInfo,
    loginUser,
    resetUser,
    updatePass,
    updateProfile,
    logoutUser,
    registerUser,
    customerStripeID,
    getMaps
} from '../utils/userApi';
import { loadStripe } from '@stripe/stripe-js';

import { INIT_MAP } from '../utils/constants';

const tagManagerArgs = {
    gtmId: 'GTM-5NTL9KC'
}

if (typeof window !== 'undefined') {
    TagManager.initialize(tagManagerArgs)
    ReactGA.initialize('UA-207974373-1');
    ReactGA.ga('send', 'pageview', '/');
}

//redirect for preact-router
export function Redirect(props) {
    useEffect(() => {
        route(props.to, true);
    }, [props.to]);

    return null;
}

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
//const stripePromise = loadStripe('pk_test_CEpiY0xpWRi73weE7bt3MkO6');

const stripePromise = loadStripe('pk_live_zo8JKuNg98OJCfxU4CHZKQq8');

const App = () => {
    const [user, setUser] = useLocalStorageState('user');
    const [showModal, setShowModal] = useState(false);
    const [showSignInModal, setShowSignInModal] = useState(false);
    const [showSignUpModal, setShowSignUpModal] = useState(false);
    const [showSignIn, setShowSignIn] = useState(false);
    const [showSignUp, setShowSignUp] = useState(false);
    const [showCheckout, setShowCheckout] = useState(false);

    const defaultProduct = {
        "title": "Digital Map File",
        "field_product_width": "12",
        "field_product_height": "12",
        "field_price": "10.00",
        "field_product_front_image": "https://api.cartocuts.com/sites/default/files/styles/product/public/images/products/2022-06/Map-6229.png?itok=5-mG_IJR ",
        "field_stripe_id": "price_0HRsvMERgOuD5W8CdmsyWKAt"
    }
    const [selectedProduct, setSelectedProduct] = useState(defaultProduct);

    const [productType, setProductType] = useState("Digital");
    const [content, setContent] = useState();

    /** user map display options */
    const [customMarkers, setCustomMarkers] = useState(INIT_MAP.customMarker); // array of custom markers and labels to add to the map.
    const [labels, setLabels] = useState(INIT_MAP.labels); // array of toggled on labels
    const [layers, setLayers] = useState(INIT_MAP.layers); // array of toggled on layers

    const [mapId, setMapId] = useState();
    const [mapUrl, setMapUrl] = useState();
    const [mapSize, setMapSize] = useState(INIT_MAP.mapSize);
    const [mapStyle, setMapStyle] = useState(INIT_MAP.mapStyle); // The Mapbox style. A string url or a MapboxGL style object (regular JS object or Immutable.Map).
    const [mapStyleName, setMapStyleName] = useState(INIT_MAP.mapStyleName); // The name of the loaded mapbox style. Using this in combination with map.on('styledata') to listen for when we change map styles. Need this extra state because 'styledata' fires whenever a layer/label is toggled and we need to reset the layer/label checkboxes only when we change styles.
    const [mapTitle, setMapTitle] = useState();
    const [viewport, setViewport] = useState(INIT_MAP.viewport);
    const [userMaps, setUserMaps] = useState();
    const [mapRotation, setMapRotation] = useState(0);
    const [myIndex,incrementIndex] = useState(1);
    const randomNumber = Math.floor(Math.random() * 10000);


    const login = async event => {
        event.preventDefault();

        try {
            const { username, password } = event.target.elements;
            const user = await loginUser(username.value, password.value);
            const userInfo = await getUserInfo(user);
            ReactGA.event({
                category: 'User',
                action: 'Logged in'
            });
            setUser({
                ...user,
                ...userInfo[0],
            });
        } catch (error) {
            return error;
        }
    };

    const autologin = async () => {
        console.log("auto-login")
        try {
            const user = await loginUser(process.env.PREACT_APP_USER , process.env.PREACT_APP_PASS );
            const userInfo = await getUserInfo(user);
            setUser({
                ...user,
                ...userInfo[0],
            });
        } catch (error) {
            return error;
        }
    };

    const reset = async event => {
        event.preventDefault();

        try {
            const { email } = event.target.elements
            ReactGA.event({
                category: 'User',
                action: 'Password Reset Send'
            });
            resetUser(email.value);
        } catch (error) {
            return error;
        }
    };

    const password = async event => {
        //alert("password function")

        event.preventDefault();

        try {
            const { name, temp, password } = event.target.elements
            updatePass(name.value, temp.value, password.value, setUser);
        } catch (error) {
            return error;
        }
    };

    const register = async event => {
        event.preventDefault();

        try {
            const { username, email, password, newsletter, firstname, lastname, company, terms } = event.target.elements;
            const stripeId = await customerStripeID(username.value, email.value);
            const result = await registerUser(
                username.value,
                email.value,
                password.value,
                stripeId,
                newsletter.value,
                firstname.value,
                lastname.value,
                company.value,
                terms.value
            );

            ReactGA.event({
                category: 'User',
                action: 'Created Account'
            });

            // double check we create a user successfully
            if (result.uuid) {
                // then login new user
                login(event);
            } else {
                throw Error('Unable to create new user');
            }
        } catch (error) {
            return error;
        }
    };

    const update = async event => {
        event.preventDefault();
        try {
            const { uuid, username, newsletter, firstname, lastname, company } = event.target.elements;
            const result = await updateProfile(
                uuid.value,
                username.value,
                // email.value,
                newsletter.value,
                firstname.value,
                lastname.value,
                company.value,
                user
            );
        } catch (error) {
            return error;
        }
    };

    const logout = async () => {
        try {
            await logoutUser(user);
            setUser(null);
        } catch (error) {
            console.error('Error in signOut user');
            return error;
        }
    };

    const updateUser = async () => {
        const status = await getLoginStatus(user);
        if (status === '1') {
            const userInfo = await getUserInfo(user);
            setUser(prevUser => ({
                ...prevUser,
                ...userInfo[0],
            }));
        } else {
            setUser(null);
            return;
        }
    };

    const resetMap = () => {
        setCustomMarkers(INIT_MAP.customMarker);
        setLabels(INIT_MAP.labels);
        setLayers(INIT_MAP.layers);
        setMapSize(INIT_MAP.mapSize);
        setMapRotation(0);
        setMapStyle(INIT_MAP.mapStyle);
        setMapStyleName(INIT_MAP.mapStyleName);
        setMapTitle(INIT_MAP.mapTitle);
        setViewport(INIT_MAP.viewport);
    };



    /** If user found in local storage when app loads, check to see if auth is still valid */
    useEffect(() => {
        // console.log(process.env.PREACT_APP_SHOP_TYPE)
        if (user) {
            updateUser();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div id='app'>
            <AppWrapper>
                <Global styles={GlobalStyles} />
                <Header
                    login={login}
                    logout={logout}
                    register={register}
                    reset={reset}
                    password={password}
                    showSignInModal={showSignInModal}
                    setShowSignInModal={setShowSignInModal}
                    showSignUpModal={showSignUpModal}
                    setShowSignUpModal={setShowSignUpModal}
                    showModal={showModal}
                    setShowModal={setShowModal}
                    showCheckout={showCheckout}
                    setShowCheckout={setShowCheckout}
                    stripePromise={stripePromise}
                    updateUser={updateUser}
                    user={user}
                    content={content}
                    setContent={setContent}
                    getCurrentUrl={getCurrentUrl}
                />
                <MainContainer>
                    <div className="mobile-warning-wrapper">
                    <div className="mobile-warning">
                    <h2>Cartocuts Map Designer</h2>
                    <h4>Needs more screen space to give you the best experience</h4>  
            
                    <h5> Currently we do not support mobile or smaller tablets but we are working on improving the experience for smaller devices</h5>
                    <h3>Please visit us on a larger screen. Thanks!</h3>
                    </div>

                    </div>
                    <Router>
                        <Redirect path="/" to={process.env.PREACT_APP_HOMEPAGE} />
                        <MapConfigurator
                            path='/make-map'
                            customMarkers={customMarkers}
                            setCustomMarkers={setCustomMarkers}
                            labels={labels}
                            setLabels={setLabels}
                            layers={layers}
                            setLayers={setLayers}
                            mapSize={mapSize}
                            setMapSize={setMapSize}
                            mapStyle={mapStyle}
                            setMapStyle={setMapStyle}
                            mapStyleName={mapStyleName}
                            setMapStyleName={setMapStyleName}
                            mapTitle={mapTitle}
                            setMapTitle={setMapTitle}
                            viewport={viewport}
                            setViewport={setViewport}
                            myIndex={myIndex}
                            incrementIndex={incrementIndex}
                            setMapRotation={setMapRotation}
                            mapRotation = {mapRotation}
                        />
                        <MapPreview
                            path='/preview'
                            setShowSignInModal={setShowSignInModal}
                            setShowSignUpModal={setShowSignUpModal}
                            setShowSignIn={setShowSignIn}
                            setShowSignUp={setShowSignUp}
                            showCheckout={showCheckout}
                            setShowCheckout={setShowCheckout}
                            user={user}
                            updateUser={updateUser}
                            resetMap={resetMap}
                            customMarkers={customMarkers}
                            labels={labels}
                            layers={layers}
                            mapSize={mapSize}
                            mapId={mapId}
                            setMapId={setMapId}
                            mapUrl={mapUrl}
                            setMapUrl={setMapUrl}
                            mapStyle={mapStyle}
                            setMapStyle={setMapStyle}
                            mapTitle={mapTitle ? (mapTitle) : (`Map-${randomNumber}`)}
                            setMapTitle={setMapTitle}
                            viewport={viewport}
                            selectProduct={selectedProduct}
                            productType={productType}
                            login={login}
                            autologin={autologin}
                            logout={logout}
                            mapRotation={mapRotation}
                            setMapRotation={setMapRotation}
                        />

                        <MyMaps
                            path='/maps'
                        />
                        <Products
                            path='/products'
                            selectProduct={selectedProduct}
                            setSelectedProduct={setSelectedProduct}
                            setProduct={setSelectedProduct}
                            updateProduct={setSelectedProduct}
                            setProductType={setProductType}
                            setMapSize={setMapSize}
                            mapSize={mapSize}
                            viewport={viewport}
                            setViewport={setViewport}
                            mapTitle={mapTitle}
                            setMapTitle={setMapTitle}
                            mapRotation={mapRotation}
                            setMapRotation={setMapRotation}
                            myIndex={myIndex}
                            incrementIndex={incrementIndex}
                        />
                        <UserEdit
                            path='/user/edit'
                            user={user}
                            update={update}
                            reset={reset}

                        />
                        <UserReset
                            path='/user/reset'
                            password={password}
                            reset={reset}
                            user={user}
                            setUser={setUser}

                        />
                        <UserSignUp
                            path='/user/register'
                            setContent={setContent}
                            setShowModal={setShowModal}
                            register={register}
                        />
                        <UserSignIn
                            path='/user/login'
                            login={login}
                            reset={reset}
                            setShowSignUpModal={setShowSignUpModal}
                            showModal={showModal}
                            setShowModal={setShowModal}
                        />
                    </Router>
                </MainContainer>
                <Footer />
            </AppWrapper>
        </div>
    );
};

const AppWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const MainContainer = styled.main`
  flex: 1;
`;

export default App;
